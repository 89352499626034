<template>
  <v-app-bar app class="align-center account-header" color="#fff" fixed>
    <!--    logo-->
    <span @click="$router.push('/my-campaigns')" class="cursor-pointer">
      <img :src="pivotAdd" height="40" alt="Pivotad Logo"/>
    </span>
    <!--my campaigns button-->
    <v-menu offset-y style="box-shadow: none" left>
      <template v-slot:activator="{ on }">
        <v-list style="background-color: transparent" class="ma-0 pl-10 pa-0">
          <v-list-item class="pa-0 ma-0">
            <v-list-item-action v-on="on" class="ma-0 pa-0">
              <div class="d-flex align-center" style="cursor: pointer">
                <div class="font-family-raleway-medium">
                  <strong class="font-raleway-regular">{{
                    getSelectedAccountName
                  }}</strong>
                  <div style="font-size: 10px; color: gray">
                    {{ numberWithSpaces(getSelectedAccountCustomerId) }}
                  </div>
                </div>
                <v-icon style="cursor: pointer" class="ml-3">
                  mdi-chevron-down</v-icon
                >
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
      <v-list style="width: 300px">
        <v-list-item>
          <v-list-item-title style="cursor: pointer">
            <strong>{{ $t("authorized_page_header.my_accounts") }}</strong>
            <v-divider class="mt-2" />
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="(item, index) in accounts"
          :key="index"
          @click="handleClickAccount(item.id, item.customerId)"
          dense
        >
          <v-list-item-title
            class="d-flex align-center"
            style="cursor: pointer"
          >
            <div class="font-weight-bold">
              <strong class="font-family-raleway-medium">{{
                item.accountName
              }}</strong>
              <div style="color: gray; font-size: 10px" class="mt-1">
                {{ numberWithSpaces(item.customerId.toString()) }}
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-icon
              v-if="accountId == item.customerId"
              class="ml-2 mb-1 color-blue"
              size="medium"
            >
              mdi-check-circle
            </v-icon>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title style="cursor: pointer">
            <strong>{{
              $t("authorized_page_header.google_ads_accounts")
            }}</strong>
            <v-divider class="mt-2" />
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="(item, index) in getUser.googleAdsAccounts"
          :key="index"
          @click="
            handleClickGoogleAds(item.resourceName, item.resourceName, item)
          "
          dense
        >
          <v-list-item-title
            class="d-flex align-center"
            style="cursor: pointer"
          >
            <div class="font-weight-bold">
              <strong class="font-family-raleway-medium">{{
                item.name
              }}</strong>
              <div style="color: gray; font-size: 10px" class="mt-1">
                {{ numberWithSpaces(item.resourceName) }}
              </div>
            </div>
            <v-spacer />
            <v-icon
              v-if="accountId == item.resourceName"
              class="ml-2 mb-1 color-blue"
              size="medium"
            >
              mdi-check-circle
            </v-icon>
            <v-btn
              small
              class="not-linked-style font-family-raleway-medium ml-2"
              style="cursor: default"
              v-if="item.manager"
            >
              <span style="color: #0057ff">MCC</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-btn
            small
            style="width: 100%; border-radius: 10px !important"
            to="/welcome"
            class="font-family-raleway-bold button"
            height="40"
          >
            <v-icon> mdi-plus</v-icon>
            <span class="text-capitalize" style="font-size: 14px"
              >add account</span
            >
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <router-link
      class="font-family-raleway-medium text-decoration-none"
      to="/my-campaigns"
      active-class="color-primary"
    >
      <span
        :class="
          getRouteName === 'MyCampaigns'
            ? 'color-blue font-weight-bold'
            : 'color-black'
        "
        class="ml-15"
        >{{ $t("authorized_page_header.my_campaigns") }}</span
      >
    </router-link>
    <router-link
      class="font-family-raleway-medium text-decoration-none mx-10"
      to="/google-ads-account"
      active-class="color-primary"
    >
      <span
        :class="
          getRouteName === 'GoogleAdsAccount'
            ? 'color-blue font-weight-bold'
            : 'color-black'
        "
        >{{ $t("authorized_page_header.my_accounts") }}</span
      >
    </router-link>
    <router-link
      class="font-family-raleway-medium text-decoration-none mr-10"
      to="/billing"
      active-class="color-primary"
    >
      <span
        :class="
          getRouteName === 'Billing'
            ? 'color-blue font-weight-bold'
            : 'color-black'
        "
        >{{ $t("authorized_page_header.billing") }}</span
      >
    </router-link>
    <v-spacer></v-spacer>
<!--    <select-language-component :authorized="true" />-->
    <v-divider v-if="!getBreakPoint" vertical inset class="mx-7" />
    <!--user fullName-->
    <div v-if="!getBreakPoint" class="d-block text-right mr-2">
      <strong class="font-family-raleway-bold">{{ getUser.fullName }}</strong>
      <p class="pb-0 mb-0 color-black font-family-raleway-medium">
        {{ getUser.email }}
      </p>
    </div>
    <!--menu for settings,log out-->
    <v-menu offset-y style="box-shadow: none" class="ml-3" rounded="lg">
      <template v-slot:activator="{ on }">
        <v-list style="background-color: transparent">
          <v-list-item class="px-3">
            <div
              v-if="getUser.userAvatar"
              class="d-flex align-center"
              style="cursor: pointer"
              v-on="on"
            >
              <v-avatar name="Asilbek">
                <img
                  :src="getImagePath(getUser.userAvatar)"
                  :alt="getUser.fullName"
                  style="object-fit: cover"
                />
              </v-avatar>
            </div>
            <div v-else>
              <v-avatar>
                <div class="noImageStyle" style="cursor: pointer" v-on="on">
                  {{ getFirstLetterOfName }}
                </div>
              </v-avatar>
            </div>
            <span v-on="on" style="cursor: pointer"
              ><v-icon color="black">mdi-chevron-down</v-icon></span
            >
          </v-list-item>
        </v-list>
      </template>
      <v-list style="width: 160px">
        <v-list-item @click="$router.push('/help')">
          <v-list-item-title
            class="d-flex align-center"
            style="cursor: pointer"
          >
            <img :src="helpIcon" />
            <span class="ml-2 color-black text font-family-raleway-medium">{{
              $t("authorized_page_header.help")
            }}</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push('/profile-settings')">
          <v-list-item-title
            class="d-flex align-center"
            style="cursor: pointer"
          >
            <img :src="settingsImg" />
            <span class="ml-2 font-family-raleway-medium">{{
              $t("authorized_page_header.settings")
            }}</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="handleLogout">
          <v-list-item-title
            class="d-flex align-center"
            style="cursor: pointer"
          >
            <img :src="logoutImg" />
            <span class="ml-2 font-family-raleway-medium">{{
              $t("authorized_page_header.logout")
            }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import pivotAdd from "../../assets/icon/Pivotadd_black.svg";
import helpIcon from "../../assets/icon/help.svg";
import settingsImg from "../../assets/img/Settings.svg";
import logoutImg from "../../assets/img/log-out.svg";
import { accountService } from "@/services/account";
import { baseUrl } from "@/main";
import { mapGetters } from "vuex";
import mccLinkIcon from "../../assets/icon/mcc-link.svg";

export default {
  name: "Header",
  data: () => ({
    pivotAdd,
    helpIcon,
    settingsImg,
    logoutImg,
    accountName: "",
    accounts: [],
    user: {},
    accountId: "",
    mccLinkIcon,
  }),
  async created() {
    const userId = localStorage.getItem("userId");
    this.user = this.getUser;
    const res = await accountService.getByUserId(userId);
    this.accounts = res.data;

    const cusId = localStorage.getItem("customerId");
    if (cusId) {
      this.accountId = cusId;
    } else {
      if (res?.data.length > 0) {
        localStorage.setItem("customerId", res?.data[0]?.customerId);
        this.accountId = res?.data[0]?.customerId;
      } else {
        await this.$router.push("/welcome");
      }
    }
  },
  computed: {
    ...mapGetters(["getUser"]),
    getBreakPoint() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    getRouteName() {
      return this.$route.name || "";
    },

    getFirstLetterOfName() {
      return this.getUser?.fullName?.charAt(0).toUpperCase();
    },

    getSelectedAccountName() {
      return (
        this.accounts?.find((item) => item.customerId == this.accountId)
          ?.accountName ||
        this.getUser?.googleAdsAccounts?.find(
          (item) => item.resourceName == this.accountId
        )?.name ||
        this.$t("authorized_page_header.select_account")
      );
    },
    getSelectedAccountCustomerId() {
      return (
        this?.accounts
          ?.find((item) => item.customerId == this.accountId)
          ?.customerId.toString() || ""
      );
    },
  },
  methods: {
    getImagePath(url) {
      return baseUrl + "/" + url;
    },
    handleLogout() {
      localStorage.clear();
      this.$router.push("/");
    },
    handleClickAccount(accountId, customerId) {
      localStorage.setItem("accountId", accountId);
      localStorage.setItem("customerId", customerId);
      this.accountId = customerId;
      window.location.reload();
    },
    handleClickGoogleAds(accountId, customerId, item) {
      if (!item.manager) {
        localStorage.setItem("accountId", accountId);
        localStorage.setItem("customerId", customerId);
        this.accountId = customerId;
        window.location.reload();
      }
    },
    numberWithSpaces(val) {
      let phone = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
      return phone.replaceAll(" ", "-");
    },
  },
};
</script>
<style lang="scss" scoped>
.account-header {
  box-shadow: none !important;
  border-bottom: 1px solid #dedbe3 !important;
}

.user-name {
  @media screen and (max-width: 600px) {
    display: none !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.noImageStyle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #150da8;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin: 0;
}
.mcc-linked-style {
  background-color: #dae3f8 !important;
  box-shadow: none !important;
  color: #0057ff !important;
  font-weight: bold;
  height: 25px !important;
  font-size: 10px !important;
  border-radius: 10px;
}
</style>
